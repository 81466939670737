<template>
  <div class="">
    <div class="flex justify-between items-center mb-6 border-b pb-6">
      <div>
        <h1 class="text-4xl">New Cash Collection</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>
    </div>

    <div class="flex flex-col">
      <form class="bg-white shadow rounded-md p-5" @submit.prevent="onSubmit">
        <h1 class="px-3 text-2xl font-bold mb-4">Cash Collection Detailes</h1>
        <div class="flex flex-wrap overflow-hidden">
          <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                Date
              </label>
              <input
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                @change="loadData()"
                v-model="form.date"
                type="date"
                :class="{
                  'is-invalid': form.errors.has('date'),
                }"
                required
              />
            </div>
          </div>

          <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                Branch
              </label>
              <div
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
              >
                <div v-if="branch">
                  {{ branch.name }}
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
          
            <button
              class="
                px-4
                py-2
                rounded
                text-white
                inline-block
                shadow-lg
                bg-blue-500
                hover:bg-blue-600
                focus:bg-blue-700
              "
              type="submit"
            >
              Save
            </button>
          </div> -->
        </div>
        <div class="w-full text-center my-3">
          <p class="text-red-600" v-if="showTopError">{{ topErrorMessage }}</p>
        </div>
        <hr />
        <template v-if="form.total_sales == null">
          <div class="text-center py-4 text-gray-600">
            <p>Please select a date & staff to create collection</p>
          </div>
        </template>
        <template v-if="form.total_sales == 0">
          <div class="text-center py-4 text-gray-900">
            <h1 class="text-xl font-bold">
              No sales or deposits found for {{ form.staff.first_name }}
              {{ form.staff.last_name }} on
              {{ form.date }}
            </h1>
          </div>
        </template>
        <template
          v-if="
            form.new_collection_results && form.new_collection_results.total > 0
          "
        >
          <div class="flex flex-wrap overflow-hidden">
            <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/4">
              <!-- Column Content -->
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-normal mb-2"
                  for="username"
                >
                  Bank
                </label>
                <input
                  class="
                    shadow
                    appearance-none
                    border
                    rounded
                    w-full
                    py-2
                    px-3
                    text-gray-700
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  name="name"
                  v-model="form.bank"
                  type="text"
                  :class="{
                    'is-invalid': form.errors.has('bank'),
                  }"
                  required
                  placeholder="Enter bank name"
                />
              </div>
            </div>

            <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/4">
              <!-- Column Content -->
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-normal mb-2"
                  for="username"
                >
                  Bank Slip Number
                </label>
                <input
                  class="
                    shadow
                    appearance-none
                    border
                    rounded
                    w-full
                    py-2
                    px-3
                    text-gray-700
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  name="name"
                  v-model="form.bank_slip_number"
                  type="text"
                  :class="{
                    'is-invalid': form.errors.has('bank_slip_number'),
                  }"
                  required
                  placeholder="Enter bank slip number"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-wrap overflow-hidden">
            <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/4">
              <!-- Column Content -->
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-normal mb-2"
                  for="username"
                >
                  Total Sales
                </label>
                <h1 class="text-xl py-2 font-bold" @copy.prevent>
                  GMD {{ formatPrice(form.new_collection_results.total) }}
                </h1>
              </div>
            </div>
            <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
              <!-- Column Content -->
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-normal mb-2"
                  for="username"
                >
                  Total Deposited
                </label>
                <div class="flex items-center">
                  <h1 class="text-xl py-2 font-bold">GMD</h1>

                  <input
                    class="
                      appearance-none
                      font-bold
                      w-full
                      text-xl
                      py-2
                      px-3
                      text-gray-900
                      leading-tight
                      focus:outline-none focus:shadow-outline
                    "
                    @copy.prevent
                    @paste.prevent
                    name="name"
                    v-model="form.total_deposited"
                    type="number"
                    :class="{
                      'is-invalid': form.errors.has('total_deposited'),
                    }"
                    required
                    placeholder="Enter the total amount collected by the bank"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="mx-3">
            <div
              class="
                bg-yellow-300
                border-l-4 border-yellow-500
                text-yellow-700
                p-4
              "
              v-if="form.new_collection_results.shortage > 0"
              role="alert"
            >
              <p class="font-bold">Shortage Found</p>
              <p>
                Please note that a shortage of GMD
                <b> {{ formatPrice(form.new_collection_results.shortage) }} </b
                >was found.
              </p>
            </div>

            <p
              v-if="form.new_collection_results.shortage > 0"
              class="text-red-500 my-4"
            >
              Shortage of GMD
              <b>{{ formatPrice(form.new_collection_results.shortage) }}</b> was
              found in worksheets
            </p>

            <button
              type="button"
              @click="showBreakDown = !showBreakDown"
              class="text-blue-500 text-sm"
            >
              <span v-if="!showBreakDown">
                Show Worksheets details breakdown</span
              >
              <span v-else> Hide Worksheets details breakdown</span>
            </button>

            <table v-if="showBreakDown" class="table-auto my-4">
              <thead>
                <tr>
                  <th class="px-4 py-2 border">#ID</th>
                  <th class="px-4 py-2 border">Shortage</th>
                  <th class="px-4 py-2 border">Surplus</th>
                  <th class="px-4 py-2 border">Deposits</th>

                  <th class="px-4 py-2 border">Sales</th>
                  <th class="px-4 py-2 border">Cash in hand</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(ws, i) in form.new_collection_results.worksheets"
                  :key="i"
                >
                  <td class="border px-4 py-2">#{{ ws.ws_id }}</td>
                  <td class="border px-4 py-2">
                    GMD {{ formatPrice(ws.shortage) }}
                  </td>
                  <td class="border px-4 py-2">
                    GMD {{ formatPrice(ws.surplus) }}
                  </td>
                  <td class="border px-4 py-2">
                    GMD {{ formatPrice(ws.total_deposits) }}
                  </td>
                  <td class="border px-4 py-2">
                    GMD {{ formatPrice(ws.total_sales / 100) }}
                  </td>
                  <td class="border px-4 py-2">
                    GMD {{ formatPrice(ws.cash_in_hand / 100) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="flex-wrap overflow-hidden">
            <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
              <!-- Column Content -->
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-normal mb-2"
                  for="username"
                >
                  Enter comment
                </label>
                <textarea
                  class="
                    shadow
                    appearance-none
                    border
                    rounded
                    w-full
                    py-2
                    px-3
                    text-gray-700
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  v-model="form.remarks"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>

            <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
              <button
                class="
                  px-4
                  py-2
                  rounded
                  text-white
                  inline-block
                  shadow-lg
                  bg-blue-500
                  hover:bg-blue-600
                  focus:bg-blue-700
                "
                :class="{ 'opacity-50 cursor-not-allowed': isLoading }"
                type="submit"
                :disabled="isLoading"
              >
                <span v-if="isLoading">Loading...</span>
                <span v-else>Save Collection</span>
              </button>
            </div>
          </div>
        </template>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Form from "vform";
import { mapGetters } from "vuex";

import checkPermissionExist from "../../../mixins/checkPermissionExist";

// import BranchSales from "../../components/Charts/BranchSales";

export default {
  middleware: "auth",
  layout: "default",

  mixins: [checkPermissionExist],
  computed: mapGetters({
    user: "auth/user",
  }),

  components: {
    // BranchSales,
    // HelloWorld
  },

  data: () => ({
    isLoading: false,
    staffs: [],
    form: new Form({
      date: null,
      staff: null,
      bank: "VISTA BANK",
      bank_slip_number: "",
      total_sales: null,
      branch: null,
      total_deposited: "",

      new_collection_results: null,
    }),
    showBreakDown: false,
    branch: null,
    branchesSales: null,
    showTopError: false,
    topErrorMessage: "",
  }),

  created() {
    if (!this.checkPermissionExist(this.user.data, "cashcollection-create")) {
      this.$router.go(-1);
      return false;
    }
  },

  mounted() {
    this.loadBranch(this.user.data.staff.branch_id);
  },

  methods: {
    async loadBranch(id) {
      try {
        const { data } = await axios.get(`/branch/${id}`);

        this.branch = data;
        this.form.branch = data;

        return true;
      } catch (e) {
        console.log("Error loading branch", e);

        return false;
      }
    },

    async loadData() {
      if (!this.form.date && !this.form.branch) {
        this.showTopError = true;
        this.topErrorMessage = "Please select both date & branch to continue";

        return false;
      }

      this.showTopError = false;

      try {
        const { data } = await axios.get(
          `/newconnection?date=${this.form.date}&branch=${this.form.branch.id}`
        );

        this.form.new_collection_results = data;

        return true;
      } catch (e) {
        console.log("Error", e);

        return false;
      }
    },

    async loadStaffs(id) {
      // Submit the form
      console.log("SATFF", id);
      try {
        const { data } = await axios.get(`/staffs/branch/${id}`);

        this.staffs = data.data;
        // this.isLoading = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },

    async onSubmit() {
      // Submit the form
      if (this.isLoading) return;
      this.isLoading = true;


      try {
        await this.form.post("/cashcollection/store");

        this.$router.push( "/cashcollection");
        this.isLoading = false;

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
